.contact-main {
    background-image: url('../../assets/con_bg.jpg');
    background-position: center;
    background-size: cover;
}

.contact-main>div:nth-child(3) {
    /* border: 1px solid red; */


}

.contact-main h5 {
    color: #A6A4A6;
    line-height: 48px;

}

.contact-main h5>span {
    color: var(--textPrimary);
    padding-left: 20px;
}

.contact-main .contact-section {
    width: 80%;
    margin: 0 auto;

    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* align-items: center; */
}

.contact-main .contact-section form {
    padding: 8% 10%;
    background-color: #E7E8EC;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-main .contact-section form input,
.contact-main .contact-section form textarea {
    border: none;
    background-color: transparent;
    resize: none;
    padding: 0 10px;
    border-bottom: 1px solid #bdbdbd;
    width: 100%;
    display: block;
    font-size: 16px;


}

.contact-main .contact-section form input:focus,
.contact-main .contact-section form textarea:focus {
    outline: none;
    border-bottom: 1px solid var(--dark);
}

.contact-main .contact-section form p {
    display: flex;
}

@media (max-width:768px) {
    .contact-main .contact-section form p {
        gap: 10px;
    }

    .contact-main .contact-section form input {
        padding: 0 3px;
    }
}

.contact-main .contact-section form p input {
    flex: 1;
}

.contact-main .contact-section form p:nth-child(2) {
    flex-direction: column;
}

.contact-main .contact-section form textarea {
    padding: 0px;
    padding-bottom: 50px;
    padding-top: 10px;
    width: 100%;

}


.contact-main .contact-section form button {
    font-size: 24px;
    font-weight: 600;
    width: fit-content;
    border: 1px solid transparent;
    transition: all .5s ease;
    border-radius: 10px;
    width: 120px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

}

.contact-main .contact-section form button .anticon {
    font-size: 30px;
    color: var(--textPrimary);
}

.contact-main .contact-section form button:hover .anticon {
    color: var(--light);
}

.contact-main .contact-section form button:hover {
    background-color: var(--dark);
    border: 1px solid var(--dark);
    color: var(--light);
}

.contact-main .contact-section>div {
    background-image: url('../../assets/contact_bg.jpeg');
    width: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-right: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

}

.contact-main .contact-section>div p {
    color: var(--light);
    background: var(--textPrimary);
    width: fit-content;
    margin-left: -12px;
    padding: 12px 20px;
}

@media (max-width:576px) {

    .contact-main .contact-section {
        width: 95%;
        flex-direction: column;
    }

    .contact-main .contact-section form {
        width: 100%;
    }

    .contact-main .contact-section>div {
        width: 100%;
        padding: 20px 40px;
    }

    .contact-main .contact-section>div p {
        margin-left: 0px;
    }

}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal p {
    text-align: center;
    margin-top: 20px;
}

.modal .anticon-check {
    font-size: 45px;
    background: var(--primary);
    color: var(--light);
    border-radius: 50%;
    padding: 15px;
}