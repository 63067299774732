.home-main .home-head {
    background-image: url('../../assets/home_bg.jpeg');
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.home-main .home-head>div {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    position: fixed;
    top: 0px;
    /* border: 1px solid red; */
    padding: 0 10%;
    width: 100%;


}

.subheading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-transform: uppercase;
}

.subheading>.horizontal-line {
    border: 1px solid var(--light);
    width: 60px;
}

.subheading>.horizontal-line-black {
    border: 1px solid var(--textPrimary);
    width: 60px;
}

.home-main .icons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 60px;
}

.home-main .product-container {
    justify-content: center;
    gap: 20px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    /* flex-wrap: wrap; */


}



.home-main .product-container .home-product-card {
    color: #555555;
    width: 100%;
    flex: 1;
    height: 100px;
    border-radius: 20px;
    padding: 14px 0px;
    border: 1px solid var(--textPrimary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}


.home-main .product-container .home-product-card:hover {
    background-color: transparent;
}

.container1::before {
    transform: scaleX(0);
    transform-origin: bottom right;
    border-radius: 20px;
}

.home-main .product-container .home-product-card:hover h4 {
    color: #ffffff !important;
}

.container1:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
    border-radius: 20px;
}

.container1::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: linear-gradient(to right, var(--primary), #f08d8d);
    z-index: -1;
    transition: transform .3s ease;
    border-radius: 20px;
}

.container1 {
    border-radius: 20px;
    position: relative;
    font-size: 5rem;
}

@media(max-width:576px) {
    .home-main .product-container {
        flex-direction: column;
    }

    .home-main .product-container .home-product-card {
        width: 80%;
        border-radius: 10px;
    }

    .container1::before {
        border-radius: 10px !important;
    }
}