.navbar {
    padding: 20px 4%;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2;
    background-color: var(--light);
    border-bottom: 1px solid var(--textPrimary);
    height: 80px;

}

.navbar .navbar-form {
    display: flex;
    justify-content: center;
    align-items: center;

}

.navbar .navbar-form input {
    width: 0px;
    padding: 5px 0px;
    color: var(--light);
    transition: width 0.75s ease;
    border-bottom: 1px solid var(--dark);
}

.white-nav .navbar-form input::placeholder {
    color: var(--light);
}

.navbar .navbar-form .input-active {
    margin: 0 10px;
    width: 150px;
    padding: 5px;

}

.white-nav .navbar-form input {
    border-bottom: 1px solid var(--light);
}

.white-nav {
    background-color: transparent;
}

.navbar>.navs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

}

.navbar ul {
    flex: 1;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    padding-left: 8%;
}

@media (max-width:993px) {
    .navbar ul {
        padding-left: 2%;
        gap: 5px;
    }

}

@media (max-width:768px) {
    .navbar>.navs-container>h1 {
        flex: 1;
    }

    .navbar {
        padding: 15px 5%;
    }
}

@media (max-width:576px) {
    .navbar {
        padding: 10px 3%;
    }

    .navbar>.navs-container {
        gap: 6px;

    }
}

.active-mobile-nav {
    width: 100%;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0px;
    transition: height 0.5s ease;
    overflow: hidden;
    /* padding: 10px 20px 0; */
}

.active-mobile-nav-activated {
    /* padding: 10px 20px; */

    height: 60vh;


}

.active-mobile-nav div {
    margin: 10px 6% 0px;
}

.active-mobile-nav ul {
    flex-direction: column;
    padding-top: 20px;
    height: 80%;
    /* border: 1px solid red; */
}

.active-mobile-nav ul li {
    font-size: 18px;
    line-height: 28px;
}

.navbar>ul li {
    font-weight: 400;
    font-size: 12px;


}

.navbar>ul {
    margin-left: auto;
    width: fit-content;
    gap: 10px;
    padding: 0px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-right: 50px;

    border-bottom: 0.5px solid var(--textPrimary);
}

.white-nav>ul {
    border-bottom: 0.5px solid var(--light);
}

.navbar>ul .circle {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #ffffff;
    padding: 0px;
}

.navbar li {
    cursor: pointer;
    font-weight: bold;

}

.navbar .icon-button {
    font-size: 20px;
}

.white-nav {
    border-bottom: none;
}

.white-nav .navs-container p,
.white-nav .navs-container a,
.white-nav li,
.white-nav button,
.white-nav h1 {
    color: var(--light)
}

.nav-detail {
    background-color: var(--light);
    height: 80vh;
    overflow-y: scroll;
    margin-top: 20px;
    border-top: 3px solid var(--primary);
    position: fixed;
    top: -100%;
    right: 0;
    width: 80%;
    transition: top 0.3s ease;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.nav-detail h4 {
    font-style: italic;
    color: var(--primary);
    margin-bottom: 20px;

}

.nav-detail .nav-picks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.nav-detail p {
    font-weight: bold;
    margin: 5px 0;
}

.visible {
    top: 100px;
    /* Move the container to the top of the viewport */
}

.nav-detail .nav-picks-container {
    background-image: url('../assets/nav_bg.jpeg');
    transition: all 2s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 200px;
    height: 150px;
    transition: all 2s ease-out;
    padding: 20px;
    position: relative;
}

.footer {
    background-color: #323232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-bottom: 30px;

}

.footer p,
.footer li,
.footer a {
    color: var(--light);
    cursor: pointer;
}

.footer a,
.footer p,
.footer div,
.footer ul {
    display: flex;
    align-items: center;
    justify-content: center;

}

.footer a,
.footer p {
    cursor: pointer;

}

.footer div {
    gap: 30px;
}

.footer ul {
    gap: 20px;
}

.footer a,
.footer p {
    gap: 10px;
    /* cursor: text; */
}

footer .contact-icons a {
    color: var(--textPrimary);
    background-color: var(--light);
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    font-size: 20px;
    transition: all 0.3s ease;
    border: 1px solid var(--light);
}

footer .contact-icons a:hover {
    background-color: var(--textPrimary);
    color: var(--light);

}

@media(max-width:576px) {
    .footer {
        padding: 30px 20px
    }

    .footer ul {
        /* flex-direction: column; */
        flex-wrap: wrap;
        gap: 30px;
    }


}

.product-card {
    margin: 10px;
}

.product-card>div {
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: cover; */
    width: 100%;
    height: 400px;
    transition: all 2s ease-out;
    padding: 20px;
    position: relative;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: hidden;
    transform: scale(1);
}

.product-card>div>h3 {
    position: absolute;
    bottom: 10px;
}

.slick-slide {
    /* margin: 0 10px; */
    /* margin-right: 20px; */
}

/* Adjust the last slide's margin to prevent overflow */
.slick-slide:last-child {
    /* margin-right: 0; */
}

.product-card>div:hover,
.nav-detail .nav-picks-container:hover {
    /* background-size: 110%; */
    transform: scale(1.1);
    transition: all 2s ease-out;
    /* animation: zoom-in 2s ease; */
}

@keyframes zoom-in {
    0% {
        transform: scale(1);
        /* Initial scale (no zoom) */
    }

    100% {
        transform: scale(1.1);
        /* Final scale (10% zoom) */
    }
}

.productSlider {}

.icon-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #D4A45E;
    font-size: 20px;
}

.icon-detail>div {
    width: 80px;
}

.icon-detail>div>img {
    width: 100%;
}

.icon-detail .anticon {
    border: 1.5px solid #D4A45E;
    border-radius: 50%;
    padding: 5px;
}

.testimonial-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    height: 300px;
    opacity: 0.7;
    /* transition: all 2s ease-in; */
}

.testimonial-card>.image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--textPrimary);

}

.testimonial-card>.image img {
    width: 100%;
}

.testimonial-card-active {
    border: 1px solid #dbdbdb;
    background-color: #eeeeee;
    border-radius: 10px;
    justify-content: space-between;
    height: 300px;
    padding: 24px;
    padding-bottom: 50px;
    opacity: 1;
    transition: all .7s;

}

@media (max-width:768px) {
    .testimonial-card-active {
        height: 220px;
    }

    .custom-arrow {
        bottom: 80px !important;
    }

    .custom-prev-arrow {
        left: 40% !important;
    }

    .custom-next-arrow {
        right: 40% !important;
    }
}

@media (max-width:576px) {
    .testimonial-card-active {
        height: 250px;
    }

    .custom-arrow {
        bottom: 50px !important;
    }

    .custom-prev-arrow {
        left: 37% !important;
    }

    .custom-next-arrow {
        right: 37% !important;
    }

    .testimonial-card-active .image {
        width: 110px !important;
        height: 110px !important;
    }
}

.testimonial-card-active .image {
    width: 125px;
    height: 125px;
    margin-top: -70px;
}

.testimonialSlider {
    padding: 0 0%;
}

.productWithDetailCard {
    display: flex;
    /* align-items: center; */
    border-radius: 20px;
    border: 1px solid #dbdbdb;
    /* margin: 50px 0; */
    height: 220px;
    overflow-y: hidden;
    transition: all 0.2s ease;
    margin: 50px 0;

}

.card-animation {
    transition: all 0.1s ease-in-out;

    --c: var(--primary);
    /* the border color */
    --b: 2px;
    /* the border thickness*/
    --g: 5px;
    /* the gap on hover */

    /* padding: calc(var(--g) + var(--b)); */
    --_g: #0000 25%, var(--c) 0;
    background:
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
        conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
    transition: .1s, background-position .1s .1s;
    cursor: pointer;
}

.card-animation:hover {
    --_i: 100%;
    transition: .3s, background-size .1s .3s;
}


.productWithDetailCard>div:nth-child(2) {
    margin: 20px 0;
    flex: 1;
    padding: 0 30px;
    height: 180px;
    overflow-y: hidden;
    /* border: 1px solid red; */
}

.productWithDetailCard div:nth-child(1) {
    padding: 20px 30px;
    flex: 1;
}

@media(max-width:993px) {
    .productWithDetailCard {
        height: 230px;
    }

    .productWithDetailCard>div:nth-child(2) {
        margin: 15px 0;
        padding: 0 20px;
        height: 200px;
    }

    .productWithDetailCard div:nth-child(1) {
        padding: 15px 20px;
    }
}

@media(max-width:768px) {
    .productWithDetailCard {
        flex-direction: column !important;
        height: auto;
    }

    .productWithDetailCard>div:nth-child(2) {
        margin: 15px 0;
        padding: 0 20px;
        height: 200px;
        border-radius: 0px !important;
    }

    .productWithDetailCard div:nth-child(1) {
        padding: 15px 20px;
        border-radius: 0px !important;
        border: none !important;
        border-bottom: 1px solid #dbdbdb !important;
    }
}


.productCard {
    cursor: pointer;
    position: relative;
    border: 1px solid #ccc;
}

.productContainer {
    position: absolute;
    /* top: 0; */
    /* width: 100%; */
    left: 0;
    right: 0;
    bottom: 0;
    /* right: 0px; */
    /* left: 0;
    right: 0;
    bottom: 0; */
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform: translateY(-100%);
    cursor: auto;
    animation: slideDown 0.3s ease-in-out forwards;
    opacity: 0;
}



.productCard.hovered .productContainer {
    transform: translateY(0);
    top: 0;
    /* opacity: 1; */
    animation: slideDown 0.3s ease-in-out forwards;
}


@keyframes slideDown {
    0% {
        top: -100%;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

.image_404 {
    width: 45%;
}

@media (max-width:768px) {
    .image_404 {
        width: 70%;
    }
}

@media (max-width:576px) {
    .image_404 {
        width: 85%;
    }
}