.about-main {}

.about-main .about-head {
    background-color: var(--bgLight);
    position: relative;
    /* min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */

}

.about-main .about-head>div {
    display: flex;
    align-items: center;
}

.about-main .about-head>div>h5,
.about-main .about-head>div>.image {}

.about-main .about-head>div>h5 {
    margin-bottom: 80px;
    padding-left: 12%;
    flex: 2;
}

.about-main .about-head>div>.image {
    border-radius: 100% 0 0 100%;
    flex: 3;
}

.about-main .about-head>div>.image img {
    width: 100%;
    border-radius: 35% 0 0 60%;
}

.about-main .about-section>div {
    margin-top: 30px;
    margin-bottom: 5px;

}

@media (max-width:768px) {


    .about-main .about-head>div>.image img {
        height: 350px;
        width: 80%;
        display: block;
        margin-left: auto;

    }

    .about-main .about-head>div>.image {
        width: 100%;
    }

    .about-main .about-head>div {
        align-items: flex-start;
    }
}

@media (max-width:576px) {
    .about-main .about-head>div>.image img {
        height: auto;
    }
}

.wave-svg {
    position: absolute;
    bottom: 0px;
}

.about-main .about-section {
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.about-main .about-section>div:nth-child(1) {
    flex: 3;

}

.about-main .about-section>div:nth-child(2) {
    flex: 2;
}

@media (max-width:768px) {
    .about-main .about-section>div:nth-child(2) {
        flex: 2;
        /* width: 60%; */
    }

    .about-main .about-section>div img {
        width: 70%;
        display: block;
        margin: 0 auto;
    }

    .about-main .about-section>div:nth-child(2) img {
        width: 60%;
    }

    .about-main .about-section>div {
        margin: 0;
    }
}

@media (max-width:576px) {
    .about-main .about-section>div:nth-child(2) {
        flex: 2;
        width: 90%;
    }
}