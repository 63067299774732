.screen__background {
    background-color: var(--primary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    z-index: -1;
}

.login-main .right-panel-active .screen__background {
    transform: rotateY(180deg);
}

.screen__background__shape {
    transform: rotate(45deg);
    position: absolute;
}

.screen__background__shape1 {
    height: 520px;
    width: 520px;
    background: #f1f1f1;
    background: lightcoral;
    top: -50px;
    right: 120px;
    border-radius: 0 72px 0 0;

}

.screen__background__shape2 {
    height: 220px;
    width: 220px;
    background: #6C63AC;
    background: #ce3737;
    top: -172px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape3 {
    height: 540px;
    width: 190px;
    background: linear-gradient(270deg, #5D54A4, #6A679E);
    background: linear-gradient(270deg, #c33333, #cc3636);
    top: 0px;
    right: 15px;
    border-radius: 32px;
}

.screen__background__shape4 {
    height: 400px;
    width: 200px;
    background: #7E7BB9;
    background: #f24f4f;
    top: 410px;
    right: 50px;
    border-radius: 60px;
}


.login__field {
    padding: 10px 0px;
    position: relative;
}

.login__input {
    border: none;
    border-bottom: 2px solid #D1D1D4;
    background: none;
    padding: 10px;
    padding-left: 24px;
    font-weight: 700;
    width: 75%;
    transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
    outline: none;
    border-bottom-color: var(--primary);
}

.login-main {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-main h1 {
    font-weight: bold;
    margin: 0;
}

.login-main h2 {
    text-align: center;
}

.login-main p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.login-main span {
    font-size: 12px;
}

.login-main a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.login-main button {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4B2B;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.login-main button:active {
    transform: scale(0.95);
}

.login-main button:focus {
    outline: none;
}

.login-main button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

.login-main form {
    /* background-color: #FFFFFF; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.login-main input {
    width: 100%;
}

.login-main .login-container {
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 560px;
}

.login-main .social-container .social {
    transition: all 0.3s ease;
}

.login-main .social-container .social:hover {
    background-color: var(--primary);
    color: var(--light);


}

.login-container {
    background-image: url('../../assets/contact_bg.jpeg');
}

.form-container {
    /* background-color: red; */
    background-image: url('../../assets/contact_bg.jpeg');
}

.form-container h1,
.form-container p,
.form-container span,
.form-container a,
.form-container input,
.form-container input::placeholder,
.form-container .anticon {
    color: #ffffff;
}

.form-container input::placeholder {
    color: #c3c2c2;
}

.login-main .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.login-main .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.login-main .login-container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.login-main .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.login-main .login-container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.login-main .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login-main .login-container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.login-main .overlay {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login-main .login-container.right-panel-active .overlay {
    transform: translateX(50%);
}

.login-main .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 60px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login-main .right-panel-active .overlay-panel {
    padding-right: 20px;
    padding-left: 60px;
}

.login-main .overlay-panel h1,
.login-main .overlay-panel p {
    color: var(--light);
}

.login-main .overlay-left {
    transform: translateX(-20%);
}

.login-main .login-container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.login-main .overlay-right {
    right: 0;
    transform: translateX(0);
}

.login-main .login-container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.login-main .social-container {
    margin: 20px 0;
}

.login-main .social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}