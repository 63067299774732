/* .ant-tabs-tab-active {
    border-bottom: red !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
    text-decoration: underline;

} */
.ant-tabs-nav-wrap {
    width: 100% !important;
    /* display: block !important; */
}

.ant-tabs-tab {
    font-size: 18px !important;
    padding: 0px 20px;
    /* margin: 0px 50px; */
    flex-grow: 1;
    text-align: center;
    /* border: 1px solid red !important; */
    width: 180px;
    /* text-align: center !important; */
}

.ant-tabs-nav {
    width: 100% !important;
    display: flex;
    justify-content: space-between !important;
    width: 100%;
}

.ant-tabs-tab-btn {
    width: 100%;
    text-align: center;
}